const API_URL = "./get-receipt/"; // Replace with your PHP API URL

export const fetchResponse = async (recipesData, sourcePage) => {
  // Додаємо api-key до об'єкта даних
  const requestData = {
    "api-key": "11223344",
    prompt: recipesData,
    source: sourcePage,
  };

  let recipeString = JSON.stringify(requestData); // Конвертуємо об'єкт в JSON рядок

  //console.log(recipeString);
  localStorage.setItem("last_query", JSON.stringify(recipesData));

  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: recipeString, // НЕ конвертуємо ще раз
    });

    const data = await response.json();

    return {
      status: 200,
      content: data.content,
    };
  } catch (error) {
    console.error("Error fetching response:", error);
    throw error;
  }
};
