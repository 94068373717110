import "./style.css";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FavoritesPage = () => {
  const navigate = useNavigate();

  const redirectToBack = () => {
    navigate(-1);
  };

  return (
    <section id="terms" className="section">
      <div className="container">
        <div className="terms__page_wrapper">
          <div className="terms__page">
            <h2 className="terms__page_title">Terms and conditions</h2>
            <div className="terms__page_text">
              <p>
                Lorem ipsum dolor sit amet consectetur. Augue mi tempor neque
                gravida. Arcu fames eget posuere euismod semper quis. Dui urna
                viverra pharetra non. Auctor massa sed integer nibh habitant
                imperdiet accumsan. Ut bibendum vitae libero quam morbi feugiat
                eu elementum. Non aliquet malesuada nec elit vel sapien. Vitae
                convallis nisl fringilla orci tellus arcu consectetur. Id tortor
                sagittis id nisl lobortis. Turpis elementum tellus sed neque et
                consectetur rutrum et.
              </p>
              <p>
                Vitae risus amet quis velit potenti neque condimentum id neque.
                Nullam sit mauris varius tristique tortor dolor sit pharetra
                sit. Risus tempor ut dui id mauris. Laoreet vel nam aliquet
                morbi fames nunc ullamcorper facilisis id. Aliquam suspendisse
                arcu justo lectus euismod turpis arcu lacus. Viverra elementum
                quis viverra auctor amet quis. Viverra nec quis in odio.
              </p>
              <p>
                Id gravida quis praesent urna dignissim cursus pharetra orci
                nisl. Nulla quis tempor fermentum risus. Et amet nisi ultricies
                tincidunt elementum sed nulla ultrices. Dui sed in cras feugiat
                sapien. Faucibus turpis lacus nunc mi elementum elit. Sed
                feugiat sed dignissim sed elit velit ullamcorper fermentum et.
                Sit nisl convallis tellus integer lectus eget ultricies
                pellentesque. Sit suspendisse amet arcu in. Amet blandit sed non
                quis accumsan. Duis et volutpat semper faucibus nisi sed sed
                pulvinar risus. Vitae nulla sapien sit nec tristique malesuada.
                Mauris viverra at integer magna donec justo dui nibh. Sit dolor
                eu imperdiet pharetra. Volutpat quis ac gravida convallis odio
                maecenas cursus. Et suspendisse et at arcu.
              </p>
            </div>

            <button className="terms__page_button" onClick={redirectToBack}>
              Back
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FavoritesPage;
