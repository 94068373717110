import "../../fonts/fonts.css";
import "./style.css";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";
import cn from "classnames";
import routesToPages from "../../RoutesToPages";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef(null);
  const navRef = useRef(null);
  const backButtonRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    if (pathName === routesToPages.loginPage) {
      headerRef.current.classList.add("hide");
    } else {
      headerRef.current.classList.remove("hide");
    }

    if (pathName === routesToPages.home) {
      backButtonRef.current.classList.add("hide");
    } else {
      backButtonRef.current.classList.remove("hide");
    }
  }, [pathName]);

  useEffect(() => {
    const body = document.body;

    if (isOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "";
    }

    // Очистка ефекту при розмонтуванні компонента
    return () => {
      body.style.overflow = "";
    };
  }, [isOpen]);

  const menuBtn = cn("menu__btn", {
    menu__btn_active: isOpen,
  });

  const menuNav = cn("menu__nav", {
    menu__nav_active: isOpen,
  });

  const headerHeight = () => {
    return headerRef.current.offsetHeight;
  };

  const handleMenuVisibility = () => {
    setIsOpen(!isOpen);
    navRef.current.style.top = headerHeight() + 1 + "px";
  };

  const menuRedirect = ({ target }) => {
    setIsOpen(!isOpen);
    const URL = target.dataset.href;
    navigate(URL);
  };

  const navigatePreviousPage = () => {
    setIsOpen(false);
    navigate(-1);
  };

  const handleHomeNavigate = () => {
    if (pathName === RoutesToPages.home) {
      return;
    }
    setIsOpen(false);
    navigate(RoutesToPages.home);
  };

  return (
    <header
      ref={headerRef}
      id="header__wrapper"
      className="header__wrapper hide"
    >
      <div className="header">
        <div ref={backButtonRef} className="button__back_wrapper">
          <button
            className="button_back"
            onClick={navigatePreviousPage}
          ></button>
        </div>
        <div className="logo__wrapper">
          <img
            className="logo"
            onClick={handleHomeNavigate}
            src={require("./img/logo_mini.png")}
            alt="main logo"
          />
        </div>
        <menu>
          <div onClick={handleMenuVisibility} className={menuBtn}>
            <span></span>
          </div>

          <nav onClick={menuRedirect} ref={navRef} className={menuNav}>
            <h2 className="menu__title">Menu</h2>
            <div id="menu__block" className="menu__block">
              <button
                data-href={RoutesToPages.home}
                className="menu__item menu__item_home"
              >
                <span
                  data-href={RoutesToPages.home}
                  className="menu__item_icon"
                >
                  <img src={require("./img/home.png")} alt="icon home" />
                </span>
                <span data-href={RoutesToPages.home}>Home</span>
              </button>
              <button
                data-href={RoutesToPages.favourites}
                className="menu__item menu__item_favourites"
              >
                <span
                  data-href={RoutesToPages.favourites}
                  className="menu__item_icon"
                >
                  <img
                    src={require("./img/favourites.png")}
                    alt="icon favourites"
                  />
                </span>
                <span data-href={RoutesToPages.favourites}>
                  Favorite Recipes
                </span>
              </button>
              <button
                data-href={RoutesToPages.contact}
                className="menu__item menu__item_contact"
              >
                <span
                  data-href={RoutesToPages.contact}
                  className="menu__item_icon"
                >
                  <img src={require("./img/postbox.png")} alt="icon postbox" />
                </span>
                <span data-href={RoutesToPages.contact}>Contact us</span>
              </button>
              <button
                data-href={RoutesToPages.terms}
                className="menu__item menu__item_terms"
              >
                <span
                  data-href={RoutesToPages.terms}
                  className="menu__item_icon"
                >
                  <img src={require("./img/terms.png")} alt="icon terms" />
                </span>
                <span data-href={RoutesToPages.terms}>Terms & Conditions</span>
              </button>
            </div>
          </nav>
        </menu>
      </div>
    </header>
  );
};

export default Header;
