import "./style.css";
import "../../fonts/fonts.css";
import { fetchResponse } from "../../apiRequest/fetchResponse";
import React, { useRef, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";
import LoaderPopup from "../../components/LoaderPopup/Loader";

const RecipePage = () => {
  const recipeLikeRef = useRef(null);
  const location = useLocation();
  const [parsedData, setParsedData] = useState(null);
  const [postId, setPostId] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loaderPopupRef = useRef(null);
  const [error, setError] = useState(null);
  const [anotherParsedData, setAnotherParsedData] = useState();

  // const userHash = localStorage.getItem("userHash");
  // localStorage.removeItem(userHash);

  const { recipeData, banner, newPostId } = location.state || {};

  const redirectToStartingPage = () => {
    console.log(RoutesToPages.home);
    navigate("/starting-page");
    //console.log(RoutesToPages.StartingPage);
    //    navigate(RoutesToPages.StartingPage);
  };

  function parseInput(input) {
    if (typeof input === "object") {
      return input;
    }

    if (typeof input === "string") {
      try {
        const json = JSON.parse(input);
        return json;
      } catch (e) {
        const jsonStartIndex = input.indexOf("{");
        const jsonEndIndex = input.lastIndexOf("}") + 1;

        if (jsonStartIndex !== -1 && jsonEndIndex !== -1) {
          const jsonString = input.substring(jsonStartIndex, jsonEndIndex);
          try {
            const json = JSON.parse(jsonString);
            return json;
          } catch (e) {
            throw new Error("Помилка парсингу JSON.");
          }
        } else {
          throw new Error("JSON-частина не знайдена у строкі.");
        }
      }
    }

    throw new Error("Невідомий формат вхідних даних.");
  }

  useEffect(() => {
    if (recipeData) {
      try {
        const parsedResponse = parseInput(recipeData);
        setParsedData(parsedResponse); // Оновлюємо стан parsedResponse

        // Перевірка і оновлення кількості генерацій рецептів
        const userHash = localStorage.getItem("userHash");
        if (userHash) {
          let userData = JSON.parse(localStorage.getItem(userHash)) || {
            date: new Date().toLocaleDateString(),
            generated_recipe_today: 0,
            favorites: [],
          };

          // Оновлення кількості генерацій
          if (Number.isInteger(userData.generated_recipe_today)) {
            userData.generated_recipe_today += 1;
          } else {
            userData.generated_recipe_today = 1;
          }

          localStorage.setItem(userHash, JSON.stringify(userData));
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      console.error("No recipe data found, returning to previous page");
    }

    const checkId = () => {
      // Перевірка, чи існує ідентифікатор в списку улюблених
      const userHash = localStorage.getItem("userHash");
      if (userHash) {
        const userData = JSON.parse(localStorage.getItem(userHash));
        if (userData && userData.favorites) {
          const isLiked = userData.favorites.some(
            (fav) => fav.id === newPostId.toString()
          );
          if (isLiked) {
            recipeLikeRef.current?.classList.add("recipe__liked");
            //setLike(true);
            return true;
          }
        }
      }
      return false;
    };

    checkId();

    setPostId(newPostId);
  }, [location.state, recipeData]);

  useEffect(() => {
    if (recipeLikeRef.current && postId !== null) {
      recipeLikeRef.current.dataset.id = postId;
    }
  }, [postId]); // Виконується при зміні postId

  const handleChangeLike = () => {
    console.log("toggled");
    recipeLikeRef.current.classList.toggle("recipe__liked");

    const userHash = localStorage.getItem("userHash");
    if (!userHash) {
      console.error("User hash not found in local storage");
      return;
    }

    let userData = JSON.parse(localStorage.getItem(userHash)) || {
      date: new Date().toLocaleDateString(),
      generated_recipe_today: 0,
      favorites: [],
    };

    console.log(recipeLikeRef.current);

    const recipeId = recipeLikeRef.current.dataset.id;
    console.log(recipeId);

    if (recipeLikeRef.current.classList.contains("recipe__liked")) {
      if (!userData.favorites.some((fav) => fav.id === recipeId)) {
        userData.favorites.push({
          liked: true,
          id: recipeId,
          image: banner,
          title: parsedData.title,
          time_preparing: parsedData.time_preparing,
          ingredients: parsedData.ingredients,
          instructions: parsedData.instructions,
        });
      }
    } else {
      userData.favorites = userData.favorites.filter(
        (fav) => fav.id !== recipeId
      );
    }

    localStorage.setItem(userHash, JSON.stringify(userData));
  };

  const generateAnotherRecipe = async (e) => {
    e.preventDefault();

    console.log("generate another recipe");
    const lastQuery = JSON.parse(localStorage.getItem("last_query")) || false;
    console.log(lastQuery);
    if (lastQuery) {
      // Використовуємо lastQuery для повторного запиту
      setLoading(true);

      try {
        const data = await fetchResponse(lastQuery, "recipe");
        console.log(data);

        function generateUniqueId() {
          return Math.round(Date.now() / 100);
        }

        const uniqueId = generateUniqueId();

        //JSON.parse(data.content.replace(/^```json|```$/g, '').trim()),
        if (data.status === 200) {
          const obj = {
            recipeData: parseInput(data.content),
            banner: banner,
            newPostId: uniqueId,
          };
          // console.log(obj);
          // console.log(typeof obj);
          //setAnotherParsedData(obj);
          console.log(parsedData);
          setParsedData(obj);
          //console.log(parsedData);

          navigate(window.location.pathname, {
            replace: true, // Це не змінить історію браузера
            state: {
              recipeData: obj.recipeData,
              banner: obj.banner,
              newPostId: obj.newPostId,
            },
          });
          // console.log(parsedData);
          // console.log(parsedData.ingredients);
          window.location.reload(); //Такий костиль через те що дані не зберігаються і стан не оновлюється по людськи.
        } else {
          console.log("else - 400 error for example from AI API");
          alert("AI too busy for now, please, try again)");
          //дописати виведення помилки - Somethink went wrong
          setError("Unexpected response status: " + data.status);
        }
      } catch (err) {
        console.log("catch error");
        setError("Failed to fetch response");
      } finally {
        console.log("finally step");
        setLoading(false);
      }
    } else {
      alert("Something went wrong 8-( ");
    }
  };

  useEffect(() => {
    // Виклик методу showLoader для показу LoaderPopup
    if (loading) {
      loaderPopupRef.current.showLoader();
    } else {
      loaderPopupRef.current.hideLoader();
    }
  }, [loading]);

  return (
    <>
      <section className="recipe__section">
        <div className="recipe__page_wrapper">
          <LoaderPopup ref={loaderPopupRef} />

          <div className="recipe__banner_wrapper">
            <img
              className="recipe__banner"
              src={require(`./img/${banner}.png`)}
              alt="recipe__logo"
            />
          </div>

          <div className="recipe__content">
            {parsedData ? (
              <>
                <h1 className="recipe__title">{parsedData.title}</h1>
                <div className="interactive__elements">
                  <div className="recipe__timer">
                    {parsedData.time_preparing}
                  </div>
                  <div
                    className="recipe__like"
                    ref={recipeLikeRef}
                    onClick={handleChangeLike}
                  ></div>
                </div>
                <h2 className="ingredients__title">Ingredients</h2>
                <ul>
                  {parsedData.ingredients.map((ingredient, index) => (
                    <li className="ul" key={index}>
                      {ingredient}
                    </li>
                  ))}
                </ul>
                <h2 className="instructions__title">Instructions</h2>
                <ol>
                  {parsedData.instructions.map((instruction, index) => (
                    <li className="ol" key={index}>
                      {instruction}
                    </li>
                  ))}
                </ol>
              </>
            ) : (
              <p>There is no relative recipe ((</p>
            )}
          </div>

          <div id="recipe__message" className="recipe__message">
            I'm just an AI. There might be mistakes in what I generate. Please
            check recipes before cooking.
          </div>

          <div className="pseudo__form">
            <button
              type="submit"
              className="recipe__page_button"
              onClick={redirectToStartingPage}
            >
              Finish
            </button>
          </div>
          <div className="form">
            <button className="generate__new" onClick={generateAnotherRecipe}>
              Generate a new
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecipePage;
