import "./style.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";
import WelcomePage from "../WelcomePage/WelcomePage";
import Inputmask from "inputmask";

const LoginPage = () => {
  let linkToLp = "https://www.google.com";

  const refPageWrapper = useRef(null);
  const refHashResult = useRef(null);
  const inputRef = useRef(null);
  const refLoginSection = useRef(null);
  const navigate = useNavigate();
  const [changedStatus, setChangedStatus] = useState(false);
  const refErrorBox = useRef(null);

  async function hashPhoneNumber(phoneNumber) {
    const encoder = new TextEncoder();
    const data = encoder.encode(phoneNumber);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  }

  let handleSubmit;
  let countryCode = "+333";

  handleSubmit = async () => {
    if (inputRef.current) {
      const phoneNumber = inputRef.current.value.replace(/\D/g, ""); // Видаляємо всі нецифрові символи
      const fullNumber = countryCode + phoneNumber;
      let errorString = "";

      if (fullNumber && fullNumber.length === 13) {
        const hashedPhoneNumber = await hashPhoneNumber(fullNumber);
        console.log(hashedPhoneNumber);
        localStorage.setItem("userHash", hashedPhoneNumber);

        if (fullNumber === "+333111111111") {
          // Введено всі одиниці
          // if (refHashResult.current) {
          //   refHashResult.current.innerText = 'Всі одиниці - введено некоректний номер';
          // }
          redirectToStartingPage();
        } else if (fullNumber === "+333999999999") {
          console.log("popup with error 1");

          errorString = `This number doesn't have access. Click <a className="error__link" href="${linkToLp}"><strong>here</strong></a> to activate it.`;
          showError(errorString);
        } else if (fullNumber === "+333999999998") {
          console.log("popup with error 2");

          errorString = `You don’t have access due to the low balance.`;
          showError(errorString);
        } else if (fullNumber === "+333999999997") {
          console.log("popup with error 3");

          errorString = `Number verification failed`;
          showError(errorString);
        } else {
          // Інший випадок

          console.log("sent to server");
          // if (refHashResult.current) {
          //   refHashResult.current.innerText = `Hashed Phone Number: ${hashedPhoneNumber}`;
          // }
        }
      } else {
        errorString = `Incorrect number`;
        showError(errorString);
      }
    }
  };

  const showError = (string) => {
    inputRef.current.classList.add("error");
    //  let html = document.createElement('span');
    //  html.innerHTML = string;
    console.log(string);
    refErrorBox.current.innerHTML = string;
  };

  const applyInputMask = () => {
    if (inputRef.current) {
      const im = new Inputmask("999-999-999");
      im.mask(inputRef.current);
    }

    if (refErrorBox.current) {
      refErrorBox.current.innerHTML = "";
    }
    inputRef.current.classList.remove("error");
  };

  useEffect(() => {
    if (sessionStorage.getItem("isVisitedFirst")) {
      setChangedStatus(true);
    }

    if (refLoginSection.current) {
      if (changedStatus) {
        refLoginSection.current.classList.remove("hide");
      } else {
        refLoginSection.current.classList.add("hide");
      }
    }
  }, [changedStatus]);

  const redirectToStartingPage = () => {
    navigate(RoutesToPages.home);
  };

  return !changedStatus ? (
    <WelcomePage status={changedStatus} onChangedStatus={setChangedStatus} />
  ) : (
    <>
      <section ref={refLoginSection} className="section_wrapper hide">
        <div ref={refPageWrapper} className="autent__page_wrapper">
          <div className="autent__page">
            <div className="autent__logo_wrapper">
              <img
                className="autent__logo"
                src={require("./img/login.png")}
                alt="autent__logo"
              />
            </div>
            <div id="autent__text" className="autent__page_text">
              Log in with your phone number to create personalized recipes
            </div>

            <div className="autent_number_wrapper">
              <div className="autent__countrycode">+333</div>
              <input
                ref={inputRef}
                onInput={applyInputMask}
                className="autent__number"
                id="phoneNumber"
                type="tel"
                placeholder="Enter number"
                inputmode="numeric"
              />
            </div>

            <div ref={refErrorBox} className="error__box"></div>

            <button className="autent__page_button" onClick={handleSubmit}>
              Continue
            </button>
          </div>

          <div ref={refHashResult} className="hash-result"></div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
