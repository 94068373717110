import React, {forwardRef} from "react";

const DishType = forwardRef((props, ref) => {

  return (
    <div className="preference__box preferences__type">
      <h3 className="preference__title">
        <span
          className="icon">
          <img src={require('../../pages/Preferences/img/dish-type.png')}
               alt="dish type icon"/></span>
        Dish type
      </h3>
      <div ref={ref} onClick={props.onChooseDish} className="preference__button_wrapper">
        <button data-dish="breakfast" className="preference__button">Breakfast</button>
        <button data-dish="brunch" className="preference__button">Brunch</button>
        <button data-dish="lunch" className="preference__button">Lunch</button>
        <button data-dish="dinner" className="preference__button">Dinner</button>
      </div>
    </div>
  )
})

export default DishType;