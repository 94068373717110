const RoutesToPages = {
  loginPage: '/',
  home: '/starting-page',
  preferences: '/preferences',
  favourites: '/favourites',
  contact: '/contact',
  terms: '/terms',
  recipe: '/recipe',
  singleRecipe: '/single-recipe/',
};

export default RoutesToPages;

