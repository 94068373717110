import './style.css';
import React, {useEffect, useRef, forwardRef, useImperativeHandle} from "react";
import lottie from 'lottie-web';
import animationData from './img/animation/loader.json'; // Шлях до вашого JSON файлу

const LoaderPopup = forwardRef((props, ref) => {
  const refLoaderPopup = useRef(null);
  const containerRef = useRef(null);


  useImperativeHandle(ref, () => ({
    showLoader() {
      if (refLoaderPopup.current) {
        refLoaderPopup.current.classList.remove('hide');
      }
    },
    hideLoader() {
      if (refLoaderPopup.current) {
        refLoaderPopup.current.classList.add('hide');
      }
    }
  }));

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: containerRef.current, // елемент для анімації
      renderer: 'svg', // тип рендерера
      loop: true, // безкінечний цикл
      autoplay: true, // автоматичний старт
      animationData: animationData, // самі дані анімації
    });
  
    // Прибирання анімації при розмонтаженні компонента
    return () => {
      animation.destroy();
    };
  }, []);

  
  

  return (
      <>
        <div ref={refLoaderPopup} className="loader__wrapper hide">
            <div className="loader">
                <div className="loader__logo"  ref={containerRef} style={{ width: '100%', height: '100%' }} />

            </div>
            <div className="loader__text">Preparing a delicious recipe for you...</div>
              
        </div>

      </>
    )
});

export default LoaderPopup;