import "./style.css";
import React, { useRef, useEffect, useState } from "react";
import arrayIngredients from "../../assets/list_ingredients.json";
import arrayAllergens from "../../assets/list_allergic.json";
import svgIcon from "./img/close.svg";
import DishType from "../../components/DishType/DishType";
import { fetchResponse } from "../../apiRequest/fetchResponse";
import cn from "classnames";
import LoaderPopup from "../../components/LoaderPopup/Loader";
import { useNavigate } from "react-router-dom";
import RoutesToPages from "../../RoutesToPages";

const Preferences = () => {
  const [allergiesValue, setAllergiesValue] = useState("");
  const [disabledAllergensInput, setDisabledAllergensInput] = useState(false);
  const [filteredAllergens, setFilteredAllergens] = useState(arrayAllergens);
  const [allergensToRender, setAllergensToRender] = useState([]);
  const allergiesQuantityRef = useRef(null);
  const allergensListRef = useRef(null);
  const allergenRef = useRef(null);
  const inputAllergiesWrapperRef = useRef(null);

  const [ingredientsValue, setIngredientsValue] = useState("");
  const [disabledIngredientsInput, setDisabledIngredientsInput] =
    useState(false);
  const [filteredIngredients, setFilteredIngredients] =
    useState(arrayIngredients);
  const [ingredientsToRender, setIngredientsToRender] = useState([]);
  const ingredientsQuantityRef = useRef(null);
  const ingredientsListRef = useRef(null);
  const ingredientRef = useRef(null);
  const searchedIngredientsRef = useRef(null);
  const inputIngredientsWrapperRef = useRef(null);

  const dishTypeRef = useRef(null);
  const loaderPopupRef = useRef(null);
  const [selectedDish, setSelectedDish] = useState("");

  const dietRef = useRef(null);
  const [chosenDiet, setChosenDiet] = useState("");

  const createQueryRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  //  const [response, setResponse] = useState('');
  const [queryClicked, setQueryClicked] = useState(false);

  const [recipesData, setRecipesData] = useState({});

  const visibilityGeneralPopupRef = useRef(null);
  const visibilityDishPopupRef = useRef(null);
  const visibilityIngredientsPopupRef = useRef(null);
  const visibilityConflictingItemsRef = useRef(null);

  const [showUnselectedItemsPopup, setShowUnselectedItemsPopup] = useState("");
  const [conflicts, setConflicts] = useState({
    diets: [],
    allergies: [],
    ingredients: [],
  });

  const navigate = useNavigate();

  const [dishTypeBanner, setDishTypeBanner] = useState("");

  const handleChooseDish = ({ target }) => {
    let dataTypeDish;
    Array.from(dishTypeRef.current.children).forEach((element) => {
      if (target === element) {
        target.classList.add("chosen__button");
        setSelectedDish(target.textContent);
        dataTypeDish = target.classList.contains("chosen__button")
          ? target.dataset.dish
          : "";
        setDishTypeBanner(dataTypeDish);
      } else {
        element.classList.remove("chosen__button");
      }
    });

    //dataTypeDish ?
    //sessionStorage.setItem('dish-type', dataTypeDish)
    //:
    //sessionStorage.removeItem('dish-type');
  };

  const handleChooseDiet = (event) => {
    const { target } = event || {};
    if (!target) {
      return;
    }

    let dataTypeDiet;
    Array.from(dietRef.current.children).forEach((element) => {
      if (target === element) {
        target.classList.toggle("chosen__button");

        if (target.classList.contains("chosen__button")) {
          setChosenDiet(target.textContent);
        } else {
          setChosenDiet("");
        }

        dataTypeDiet = target.classList.contains("chosen__button")
          ? target.dataset.diet
          : "";
      } else {
        element.classList.remove("chosen__button");
      }
    });

    dataTypeDiet
      ? sessionStorage.setItem("diet", dataTypeDiet)
      : sessionStorage.removeItem("diet");
  };

  const handleSearchAllergens = ({ target }) => {
    const trimmedValue = target.value.trimStart();
    setAllergiesValue(trimmedValue);

    if (target.value.length > 1) {
      allergensListRef.current.classList.remove("hide");

      let arrayUnselectedAllergens = [];

      if (allergensToRender.length > 0) {
        arrayUnselectedAllergens = arrayAllergens.filter(
          (elem) => !allergensToRender.includes(elem)
        );
      } else {
        arrayUnselectedAllergens = arrayAllergens;
      }

      const temporaryFilteredAllergens = arrayUnselectedAllergens
        .filter((item) =>
          item.toLowerCase().includes(target.value.toLowerCase())
        )
        .sort((cur, prev) => cur.localeCompare(prev));

      setFilteredAllergens(temporaryFilteredAllergens);
    } else {
      allergensListRef.current.classList.add("hide");
      setFilteredAllergens([]);
    }
  };
  const createAllergenElem = ({ target }) => {
    if (!allergensToRender.includes(target.textContent)) {
      setAllergensToRender([target.textContent, ...allergensToRender]);
    }

    setAllergiesValue("");
    allergensListRef.current.classList.add("hide");
  };
  const createItemAllergensList = () => {
    return allergensToRender.map((item, index) => (
      <button
        className="btn__allergen"
        onClick={() => removeItem(allergensToRender, index)}
        key={index}
      >
        {item}
      </button>
    ));
  };
  const resetAllergens = () => {
    setAllergiesValue("");
    setFilteredAllergens([]);
    setAllergensToRender([]);
    allergensListRef.current.classList.add("hide");
    sessionStorage.removeItem("allergens");
  };
  const highlightAllergens = () => {
    const allergensToRenderedChildren = Array.from(
      allergenRef.current.children
    );

    allergensToRenderedChildren.forEach((allergen) =>
      allergen.classList.remove("excluded")
    );

    if (allergensToRender.length > 0 && ingredientsToRender.length > 0) {
      const allergensForSelectedIngredients = arrayIngredients
        .filter((item) => ingredientsToRender.includes(item.name))
        .map((item) => item.allergy || [])
        .flat();

      const uniqueAllergensList = Array.from(
        new Set(
          allergensForSelectedIngredients
            .map((item) => item.toLowerCase())
            .join(" ")
            .split(" ")
        )
      );

      allergensToRenderedChildren.forEach((item) => {
        const separateAllergenToRenderList = item.textContent
          .toLowerCase()
          .split(" ");

        if (
          separateAllergenToRenderList.some((item) =>
            uniqueAllergensList.includes(item)
          )
        ) {
          item.classList.add("excluded");
        }
      });
    }
  };

  useEffect(() => {
    if (allergiesValue.length <= 1) {
      allergensListRef.current.classList.add("hide");
      setFilteredAllergens([]);
    }
  }, [allergiesValue, allergensListRef]);

  const handleSearchIngredients = ({ target }) => {
    const trimmedValue = target.value.trimStart();
    setIngredientsValue(trimmedValue);

    if (target.value.length > 1) {
      ingredientsListRef.current.classList.remove("hide");

      let arrayUnselectedIngredients = [];

      if (ingredientsToRender.length > 0) {
        arrayUnselectedIngredients = arrayIngredients.filter(
          (elem) => !ingredientsToRender.includes(elem.name)
        );
      } else {
        arrayUnselectedIngredients = arrayIngredients;
      }

      const temporaryFilteredIngredients = arrayUnselectedIngredients
        .filter((item) =>
          item.name.toLowerCase().includes(target.value.toLowerCase())
        )
        .sort((cur, prev) => cur.name.localeCompare(prev.name));

      setFilteredIngredients(temporaryFilteredIngredients);
    } else {
      ingredientsListRef.current.classList.add("hide");
      setFilteredIngredients([]);
    }
  };
  const createIngredientElem = ({ target }) => {
    if (!ingredientsToRender.includes(target.textContent)) {
      setIngredientsToRender([target.textContent, ...ingredientsToRender]);
    }

    setIngredientsValue("");
    ingredientsListRef.current.classList.add("hide");
  };
  const createItemIngredientsList = () => {
    return ingredientsToRender.map((item, index) => (
      <button
        className="btn__ingredient"
        onClick={() => removeItem(ingredientsToRender, index)}
        key={index}
      >
        {item}
      </button>
    ));
  };
  const resetIngredients = () => {
    setIngredientsValue("");
    setFilteredIngredients([]);
    setIngredientsToRender([]);
    ingredientsListRef.current.classList.add("hide");
    sessionStorage.removeItem("ingradients");
  };
  const highlightIngredients = () => {
    const ingredientsToRenderedChildren = Array.from(
      ingredientRef.current.children
    );

    ingredientsToRenderedChildren.forEach((ingredient) =>
      ingredient.classList.remove("excluded")
    );

    const dietChildren = Array.from(dietRef.current.children);

    dietChildren.forEach((el) => {
      el.classList.remove("excluded");
    });

    if (ingredientsToRender.length > 0 && chosenDiet) {
      const ingredientsToExclude = arrayIngredients
        .filter((item) => !item.diet.includes(chosenDiet))
        .map((item) => item.name);

      ingredientsToRenderedChildren.forEach((ingredient) => {
        const ingredientName = ingredient.textContent;

        if (ingredientsToExclude.includes(ingredientName)) {
          ingredient.classList.add("excluded");

          dietChildren.forEach((diet) => {
            const dietName = diet.textContent;

            if (dietName.includes(chosenDiet)) {
              diet.classList.add("excluded");
            }
          });
        }
      });
    }

    if (allergensToRender.length > 0 && ingredientsToRender.length > 0) {
      const matchedAllergens = arrayIngredients
        .filter((item) => ingredientsToRender.includes(item.name))
        .map((item) => {
          return {
            name: item.name,
            allergy: item.allergy
              .map((el) => el.toLowerCase())
              .join(" ")
              .split(" "),
          };
        });

      const lowerCaseAllergensToRender = Array.from(
        new Set(
          allergensToRender
            .map((el) => el.toLowerCase())
            .join(" ")
            .split(" ")
        )
      );

      const excludedElems = matchedAllergens.filter((item) =>
        item.allergy.some((allergy) =>
          lowerCaseAllergensToRender.includes(allergy)
        )
      );

      ingredientsToRenderedChildren.forEach((item) => {
        const ingredientText = item.textContent.toLowerCase();

        if (
          excludedElems.some((item) =>
            item.name.toLowerCase().includes(ingredientText)
          )
        ) {
          item.classList.add("excluded");
        }
      });
    }
  };

  useEffect(() => {
    if (ingredientsValue.length <= 1) {
      ingredientsListRef.current.classList.add("hide");
      setFilteredIngredients([]);
    }
  }, [ingredientsValue, ingredientsListRef]);

  const highlightExcludedElems = () => {
    highlightAllergens();
    highlightIngredients();
  };
  useEffect(() => {
    highlightExcludedElems();
  }, [allergensToRender, ingredientsToRender, chosenDiet]);
  const removeItem = (list, indexItem) => {
    const updatedArray = list.filter((item, index) => index !== indexItem);

    list === allergensToRender
      ? setAllergensToRender(updatedArray)
      : setIngredientsToRender(updatedArray);
  };
  // const resetPreferences = () => {
  //   // Скидання вибору типу страви
  //   handleChooseDish({}, true);
  //   console.log('chooseDish already reset');

  //   setChosenDiet('')
  //   Array.from(dietRef.current.children)
  //     .forEach(item => item.classList.remove('chosen__button'))

  //   //Скидання вибору дієти
  //   handleChooseDiet({}, true);
  //   console.log('chooseDiet also reset');
  //   //Скидання алергенів
  //   resetAllergens();
  //   console.log('allergens reset');

  //   resetIngredients();
  //   console.log('ingr reset too');
  //   //Скидання інградієнтів
  // };

  const resetPreferences = () => {
    handleChooseDish({});
    setSelectedDish("");
    handleChooseDiet({});
    setChosenDiet("");
    Array.from(dietRef.current.children).forEach((item) =>
      item.classList.remove("chosen__button")
    );
    resetAllergens();
    resetIngredients();
    setRecipesData({});
    setConflicts({ diets: [], allergies: [], ingredients: [] });
    setQueryClicked(false);
  };

  const handleOnLoadCheckData = () => {
    setQueryClicked(true);

    const dishChildren = Array.from(dishTypeRef.current.children);

    if (
      dishChildren.every(
        (item) => !item.classList.contains("chosen__button")
      ) &&
      ingredientsToRender.length < 1
    ) {
      setShowUnselectedItemsPopup("all");
    } else if (
      dishChildren.every((item) => !item.classList.contains("chosen__button"))
    ) {
      setShowUnselectedItemsPopup("dish");
    } else if (ingredientsToRender.length < 1) {
      setShowUnselectedItemsPopup("ingredients");
    } else if (
      dishChildren.some((item) => item.classList.contains("chosen__button")) &&
      ingredientsToRender.length >= 1
    ) {
      setShowUnselectedItemsPopup("");
    }

    checkUnselectedData();

    if (!selectedDish) {
      setTimeout(() => {
        // console.log(2)
        conflictingItems();
      }, 2000);
    } else {
      conflictingItems();
    }

    if (
      conflicts.diets.length < 1 &&
      conflicts.allergies.length < 1 &&
      conflicts.ingredients.length < 1 &&
      selectedDish &&
      ingredientsToRender.length > 0
    ) {
      setRecipesData({
        diet: chosenDiet || null,
        allergies: allergensToRender || null,
        ingredients: ingredientsToRender,
      });
    }
  };

  const checkUnselectedData = () => {
    if (showUnselectedItemsPopup === "all") {
      visibilityGeneralPopupRef.current.classList.add("shown");
      visibilityDishPopupRef.current.classList.remove("shown");
      visibilityIngredientsPopupRef.current.classList.remove("shown");

      setTimeout(() => {
        visibilityGeneralPopupRef.current.classList.remove("shown");
      }, 2000);
    } else if (showUnselectedItemsPopup === "dish") {
      visibilityDishPopupRef.current.classList.add("shown");
      visibilityGeneralPopupRef.current.classList.remove("shown");
      visibilityIngredientsPopupRef.current.classList.remove("shown");

      setTimeout(() => {
        visibilityDishPopupRef.current.classList.remove("shown");
      }, 2000);
    } else if (showUnselectedItemsPopup === "ingredients") {
      visibilityIngredientsPopupRef.current.classList.add("shown");
      visibilityGeneralPopupRef.current.classList.remove("shown");
      visibilityDishPopupRef.current.classList.remove("shown");

      setTimeout(() => {
        visibilityIngredientsPopupRef.current.classList.remove("shown");
      }, 2000);
    } else if (showUnselectedItemsPopup === "") {
      visibilityIngredientsPopupRef.current.classList.remove("shown");
      visibilityGeneralPopupRef.current.classList.remove("shown");
      visibilityDishPopupRef.current.classList.remove("shown");
    }
  };
  // useEffect(() => {
  //   if (queryClicked === true) {
  //     const dishChildren = Array.from(dishTypeRef.current.children);

  //     dishChildren.forEach(el => {
  //       if (dishChildren.every(item => !item.classList.contains('chosen__button'))) {
  //         el.classList.add('unselected__dish')
  //       } else {
  //         el.classList.remove('unselected__dish')
  //       }
  //     })

  //     if (ingredientsToRender.length < 1) {
  //       searchedIngredientsRef.current.classList.add('empty__field')
  //     } else {
  //       searchedIngredientsRef.current.classList.remove('empty__field')
  //     }
  //   }

  // }, [selectedDish, ingredientsToRender, queryClicked]);

  useEffect(() => {
    const dishChildren = Array.from(dishTypeRef.current.children);
    if (queryClicked === true) {
      dishChildren.forEach((el) => {
        if (
          dishChildren.every(
            (item) => !item.classList.contains("chosen__button")
          )
        ) {
          el.classList.add("unselected__dish");
        } else {
          el.classList.remove("unselected__dish");
        }
      });

      if (ingredientsToRender.length < 1) {
        searchedIngredientsRef.current.classList.add("empty__field");
      } else {
        searchedIngredientsRef.current.classList.remove("empty__field");
      }
    } else {
      dishChildren.forEach((dish) => dish.classList.remove("unselected__dish"));
      searchedIngredientsRef.current.classList.remove("empty__field");
    }
  }, [selectedDish, ingredientsToRender, queryClicked]);

  useEffect(() => {
    checkUnselectedData();
  }, [showUnselectedItemsPopup]);

  useEffect(() => {
    allergiesQuantityRef.current.textContent = allergensToRender.length;
    ingredientsQuantityRef.current.textContent = ingredientsToRender.length;

    if (allergensToRender.length >= 5) {
      setDisabledAllergensInput(true);
      inputAllergiesWrapperRef.current.classList.add("input__transparent");
    } else {
      setDisabledAllergensInput(false);
      inputAllergiesWrapperRef.current.classList.remove("input__transparent");
    }

    if (ingredientsToRender.length >= 5) {
      setDisabledIngredientsInput(true);
      inputIngredientsWrapperRef.current.classList.add("input__transparent");
    } else {
      setDisabledIngredientsInput(false);
      inputIngredientsWrapperRef.current.classList.remove("input__transparent");
    }
  }, [allergensToRender, ingredientsToRender]);

  const conflictingItems = () => {
    const excludedDiets = Array.from(dietRef.current.children)
      .filter((item) => item.classList.contains("excluded"))
      .map((item) => item.textContent.toLowerCase());

    const excludedAllergies = Array.from(allergenRef.current.children)
      .filter((item) => item.classList.contains("excluded"))
      .map((item) => item.textContent.trim().toLowerCase());

    const excludedIngredients = Array.from(ingredientRef.current.children)
      .filter((item) => item.classList.contains("excluded"))
      .map((item) => item.textContent.trim().toLowerCase());

    const excludedItems = {
      diets: excludedDiets,
      allergies: excludedAllergies,
      ingredients: excludedIngredients,
    };

    setConflicts(excludedItems);

    return excludedItems;
  };
  const checkConflictingItems = () => {
    if (
      (conflicts.allergies.length > 0 && conflicts.ingredients.length > 0) ||
      conflicts.diets.length > 0
    ) {
      visibilityConflictingItemsRef.current.classList.add("shown");

      setTimeout(() => {
        visibilityConflictingItemsRef.current.classList.remove("shown");
      }, 2000);
    } else {
      visibilityConflictingItemsRef.current.classList.remove("shown");
    }
  };

  useEffect(() => {
    checkConflictingItems();
  }, [conflicts]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      conflicts.diets.length < 1 &&
      conflicts.allergies.length < 1 &&
      conflicts.ingredients.length < 1 &&
      selectedDish &&
      ingredientsToRender.length > 0
    ) {
      // console.log(`there are no conflicting items`)

      setLoading(true);
      setError(null);

      try {
        const data = await fetchResponse(recipesData, "preferences");

        //setResponse(data);

        // Перевірка статусу відповіді
        if (data.status === 200) {
          function generateUniqueId() {
            return Math.round(Date.now() / 100);
          }

          const uniqueId = generateUniqueId();
          // console.log(uniqueId);

          navigate(RoutesToPages.recipe, {
            state: {
              recipeData: data.content,
              banner: dishTypeBanner,
              newPostId: uniqueId,
            },
          });
        } else {
          //дописати виведення помилки - Somethink went wrong
          setError("Unexpected response status: " + data.status);
        }
      } catch (err) {
        setError("Failed to fetch response");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // Виклик методу showLoader для показу LoaderPopup
    if (loading) {
      loaderPopupRef.current.showLoader();
    } else {
      loaderPopupRef.current.hideLoader();
    }
  }, [loading]);

  return (
    <div className="preferences__page_wrapper">
      <LoaderPopup ref={loaderPopupRef} />

      <div className="preferences__page">
        <h2 className="preferences__main_title">Choose your preferences</h2>

        <DishType ref={dishTypeRef} onChooseDish={handleChooseDish} />

        <div className="preference__box preferences__diet">
          <h3 className="preference__title">
            <span className="icon">
              <img src={require("./img/avocado.png")} alt="diet icon" />
            </span>
            Do you follow any diet?
          </h3>
          <div
            ref={dietRef}
            onClick={handleChooseDiet}
            className="preference__button_wrapper"
            data-diet
          >
            <button data-diet="vegetarian" className="preference__button">
              Vegetarian
            </button>
            <button data-diet="vegan" className="preference__button">
              Vegan
            </button>
            <button data-diet="paleo" className="preference__button">
              Paleo
            </button>
            <button data-diet="keto" className="preference__button">
              Keto
            </button>
            <button data-diet="low-carb" className="preference__button">
              Low-Carb
            </button>
            <button data-diet="gluten-free" className="preference__button">
              Gluten Free
            </button>
          </div>
        </div>

        <div className="preference__box preferences__allergic">
          <h3 className="preference__title">
            <span className="icon">
              <img src={require("./img/ostreida.png")} alt="Allerginc icon" />
            </span>
            <span className="preference__title-content">
              Any allergies or intolerance?
            </span>
            <span className="preference__quantity">
              (<span ref={allergiesQuantityRef}>0</span>/5)
            </span>
          </h3>

          <div className="preference__input_wrapper">
            <div ref={inputAllergiesWrapperRef} className="input__wrapper">
              <button className="preference__search_button">
                <img
                  className="preference__search_icon"
                  src={require("./img/search.png")}
                  alt="Search icon"
                />
              </button>

              <input
                disabled={disabledAllergensInput}
                onChange={handleSearchAllergens}
                value={allergiesValue}
                placeholder="Search"
                className="preference__search_input"
                type="text"
              />
            </div>
            <div ref={allergenRef} className="allergens">
              {allergensToRender.length > 0 ? createItemAllergensList() : null}
            </div>
          </div>

          <div
            ref={allergensListRef}
            onClick={createAllergenElem}
            className="preference__variables_list hide"
          >
            <ul className="filtered__allergen_wrapper">
              {filteredAllergens.map((item, index) => (
                <li className="filtered__allergen" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="preference__box preferences__ingredients">
          <h3 className="preference__title">
            <span className="icon">
              <img src={require("./img/zucchini.png")} alt="ingredients icon" />
            </span>
            <span className="preference__title-content">Ingredients</span>
            <span className="preference__quantity">
              (<span ref={ingredientsQuantityRef}>0</span>/5)
            </span>
          </h3>

          <div
            ref={searchedIngredientsRef}
            className="preference__input_wrapper"
          >
            <div ref={inputIngredientsWrapperRef} className="input__wrapper">
              <button className="preference__search_button">
                <img
                  className="preference__search_icon"
                  src={require("./img/search.png")}
                  alt="Search icon"
                />
              </button>

              <input
                disabled={disabledIngredientsInput}
                onChange={handleSearchIngredients}
                value={ingredientsValue}
                placeholder="Search"
                className="preference__search_input"
                type="text"
              />
            </div>
            <div ref={ingredientRef} className="ingredients">
              {ingredientsToRender.length > 0
                ? createItemIngredientsList()
                : null}
            </div>
          </div>

          <div
            ref={ingredientsListRef}
            onClick={createIngredientElem}
            className="preference__variables_list hide"
          >
            <ul className="filtered__ingredient_wrapper">
              {filteredIngredients.map((item, index) => (
                <li className="filtered__ingredient" key={index}>
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <form className="form" onSubmit={handleSubmit}>
          <button
            type="submit"
            ref={createQueryRef}
            onClick={handleOnLoadCheckData}
            className="create_query"
          >
            Create
          </button>

          <button className="reset" onClick={resetPreferences}>
            <span className="reset__icon">
              <img src={svgIcon} alt="reset svg" />
            </span>
            <span className="reset__title">Reset</span>
          </button>
        </form>
      </div>

      <div ref={visibilityGeneralPopupRef} className="popup__united hidden">
        {/* <div
          // onClick={handleFirstPopup}
          className="popup__close"
        ></div> */}
        <span className="popup__text">
          Please select ingredients and desired dish type
        </span>
      </div>

      <div ref={visibilityIngredientsPopupRef} className="popup__united hidden">
        {/* <div
          // onClick={handleFirstPopup}
          className="popup__close"
        ></div> */}
        <span className="popup__text">Please select ingredients</span>
      </div>

      <div ref={visibilityDishPopupRef} className="popup__united hidden">
        {/* <div
          // onClick={handleFirstPopup}
          className="popup__close"
        ></div> */}
        <span className="popup__text"> Please select desired dish type</span>
      </div>

      <div ref={visibilityConflictingItemsRef} className="popup__united hidden">
        {/* <div className="popup__close"></div> */}
        <span className="popup__text">Please change conflicting items.</span>
        {conflicts.diets.length > 0 ? (
          <span className="popup__text">
            Diets:{" "}
            <strong>
              <span className="conflicted__items">
                {conflicts.diets.join(", ")}
              </span>
            </strong>{" "}
          </span>
        ) : null}
        {conflicts.allergies.length > 0 ? (
          <span className="popup__text">
            Allergies:{" "}
            <strong>
              <span className="conflicted__items">
                {conflicts.allergies.join(", ")}
              </span>
            </strong>{" "}
          </span>
        ) : null}
        <span className="popup__text">
          Ingredients:{" "}
          <strong>
            <span className="conflicted__items">
              {conflicts.ingredients.join(", ")}
            </span>
          </strong>
        </span>
      </div>
    </div>
  );
};

export default Preferences;
